/* eslint-disable react/jsx-indent */
import { useEffect, useState, useRef } from 'react'
import {
  CardBody,
  Row,
  Col,
  Card,
  Table,
  CardHeader,
  Container,
  Spinner,
  Modal,
  ModalHeader,
  Form,
  ModalBody,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Button,
  FormFeedback,
  UncontrolledTooltip
} from 'reactstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
// import Pdf from 'react-to-pdf'
// import JsPDF from 'jspdf'
import html2pdf from 'html2pdf.js'
import notFoundProd from '../../../assets/images/not-found-prod.png'

// import logoDark from '../../../assets/images/logo-dark.png'
// import logoLight from '../../../assets/images/logo-light.png'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_ONE_COTIZACION } from '../../../graphql/queries/getCotizaciones'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { toast } from 'react-toastify'
import moment from 'moment'
/* import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  usePDF,
  Image
} from '@react-pdf/renderer' */

import {
  // getColorByStatusPrint,
  // getFullTotal,
  getFullTotalNoParse,
  getTotal,
  hasPermission,
  parseId,
  // parseId,
  parsePrice
} from '../../../helpers'
import {
  ADD_DISCOUNT_QUOTATION,
  CHANGE_STATUS_QUOTATION,
  CREATE_COMMENT_QUOTATION
} from '../../../graphql/mutations/quotations'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  ALL_PERMISSIONS,
  ERROR_MESSAGES,
  PRICE_TO_DELETE,
  QUOTATION_TYPES,
  STATUS_QUOATATIONS,
  STATUS_QUOATATIONS_OBJ
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
// import CurrencyInput from 'react-currency-input-field'
import { InputCurrency } from '../../../Components/Own/InputCurrency'
import { InfoPricesQuotation } from '../../../Components/Own/quotations/InfoPricesQuotation'

/* const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: '1cm',
    fontSize: '12px'
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logo: {
    width: '190px',
    height: 'auto'
  },
  header: {
    marginTop: '1cm',
    marginBottom: '.5cm'
  },
  nameTitleInfo: {
    color: 'gray'
  },
  nameContentInfo: {
    fontWeight: 'bold',
    color: '#000'
  },
  name: {
    textTransform: 'uppercase',
    color: '#000',
    fontWeight: 'bold'
  },
  contact: {
    color: 'gray'
  },
  infoGeneral: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px dashed #dcdcdc',
    paddingTop: '1cm',
    marginBottom: '1.5cm'
  },
  infoGeneralItem: {
    textTransform: 'uppercase'
  },
  infoGeneralItemTitle: {
    color: '#878a99',
    fontWeight: 'extrabold'
  },
  infoGeneralItemContent: {
    fontSize: '9px',
    marginTop: '6px'
  },
  table: {
    display: 'table'
  },
  tableHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '8px',
    backgroundColor: '#f3f6f9',
    padding: '10px 6px'
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '4px',
    fontSize: '10px',
    padding: '10px 6px'
  },
  itemTable: {
    flex: 1
  },
  secondTable: {
    borderTop: '1px dashed #f3f6f9',
    paddingTop: '.3cm',
    color: '#212529'
  },
  secondTBody: {
    verticalAlign: 'middle',
    marginLeft: 'auto'
    // flexDirection: 'row'
  },
  comments: {
    width: '100%',
    backgroundColor: '#dff0fa',
    padding: '20px',
    marginTop: '1.5cm',
    borderRadius: '4px',
    color: '#1f75a4',
    borderColor: '#bfe1f4'
  }
}) */

const OneCotizacion = () => {
  const navigate = useNavigate()
  const [gqlGetCotizacion, { data, loading, error, refetch }] = useLazyQuery(
    GET_ONE_COTIZACION,
    { fetchPolicy: 'network-only' }
  )
  const [
    gqlCreateComment,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_COMMENT_QUOTATION, {
    refetchQueries: [GET_ONE_COTIZACION]
  })
  const [
    gqlChangeStatus,
    {
      data: dataChangeStatus,
      loading: loadingChangeStatus,
      error: errorChangeStatus
    }
  ] = useMutation(CHANGE_STATUS_QUOTATION, {
    refetchQueries: [GET_ONE_COTIZACION]
  })
  const [
    gqlAddDiscount,
    {
      data: dataAddDiscount,
      loading: loadingAddDiscount,
      error: errorAddDiscount
    }
  ] = useMutation(ADD_DISCOUNT_QUOTATION, {
    refetchQueries: [GET_ONE_COTIZACION]
  })

  const { logout, permissions, user } = useGetUser()
  const contentRef = useRef()
  const { id } = useParams()
  const [tab, setTab] = useState('1')

  const [loadingDown, setLoadingDown] = useState(false)

  const [modalChangeStatus, setModalChangeStatus] = useState(false)
  const [modalAddDiscount, setModalAddDiscount] = useState(false)

  // const [dataPrint, setDataPrint] = useState(null)
  // const [urlPrint, setUrlPrint] = useState(null)
  // const [isConvert, setIsConvert] = useState(false)

  const [modalComment, setModalComment] = useState(false)

  const formComment = useFormik({
    initialValues: {
      comment: ''
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Campo obligatorio')
    }),
    onSubmit: ({ comment }) => {
      if (Number(id) && comment) {
        gqlCreateComment({
          variables: {
            comment: {
              comment,
              quotation: id
            }
          },
          refetchQueries: [GET_ONE_COTIZACION]
        })
      }
    }
  })
  const formChangeStatus = useFormik({
    initialValues: {
      comment: '',
      status: ''
    },
    validationSchema: Yup.object({
      comment: Yup.string().required('Campo obligatorio'),
      status: Yup.string().required('Estado es requerido')
    }),
    onSubmit: (values) => {
      const input = { ...values, quotationId: id }
      // console.log('input', input)
      gqlChangeStatus({
        variables: {
          input
        },
        refetchQueries: [GET_ONE_COTIZACION]
      })
    }
  })
  const formAddDiscount = useFormik({
    initialValues: {
      product: '',
      name: '',
      discountValue: ''
    },
    validationSchema: Yup.object({
      product: Yup.string().required('Producto es requerido'),
      name: Yup.string().required('Nombre es requerido'),
      discountValue: Yup.string().required('Valor es requerido')
    }),
    onSubmit: (values) => {
      const input = { ...values, quotation: id }
      gqlAddDiscount({
        variables: {
          input
        },
        refetchQueries: [GET_ONE_COTIZACION]
      })
    }
  })
  /**
   * @param {{node: {basePrice: string, priceauxquotationSet: {edges: {node: {name: string, value: string}}[]}, discountauxquotationSet: {edges: {node: {name: string, value: string}}[]}, product: {id: string, name: string, sku:string}}}[]} allProducts
   * @param {string | null} deleteField
   * */
  const getAllTotal = (allProducts, deleteField) => {
    let fullTot = 0
    allProducts.forEach(({ node }) => {
      const tot = getFullTotalNoParse(
        node.basePrice,
        node.discountauxquotationSet.edges,
        node.priceauxquotationSet.edges,
        deleteField
      )
      console.log('totStr', tot)
      // const tot = parseInt(totStr.replace(/\D/g, ''))
      fullTot += tot
    })
    return parsePrice(fullTot)
  }
  // Print the Invoice

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.PUEDE_VER_COTIZACION)) {
      navigate('/dashboard')
    }
  }, [permissions])

  useEffect(() => {
    if (!id || !Number(id)) {
      navigate('/cotizaciones')
    }
    gqlGetCotizacion({
      variables: {
        id
      },
      fetchPolicy: 'network-only'
    })
  }, [id])

  useEffect(() => {
    if (error) {
      toast.error(`Error: ${error.message}`)
      if (error.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [error])

  /* useEffect(() => {
    if (data) {
      if (data.listQuotations?.edges?.length > 0) {
        setDataPrint(data)
      }
    }
  }, [data]) */

  useEffect(() => {
    if (dataCreate) {
      if (
        dataCreate.quotationCreateComment &&
        dataCreate.quotationCreateComment.success
      ) {
        toast.success('Comentario agregado con éxito')
        formComment.resetForm()
        setModalComment(false)
      } else {
        toast.error(
          `Error: ${dataCreate.quotationCreateComment.errors.message}`
        )
      }
    }
  }, [dataCreate])

  useEffect(() => {
    if (errorCreate) {
      toast.error(`Error: ${errorCreate.message}`)
      if (errorCreate.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorCreate])
  useEffect(() => {
    if (dataChangeStatus) {
      if (
        dataChangeStatus.quotationChangeStatus &&
        dataChangeStatus.quotationChangeStatus.success
      ) {
        toast.success('Se cambió el estado con éxito')
        formChangeStatus.resetForm()
        setModalChangeStatus(false)
      } else {
        toast.error(
          `Error: ${dataChangeStatus.quotationChangeStatus?.errors?.message}`
        )
      }
    }
  }, [dataChangeStatus])

  useEffect(() => {
    if (errorChangeStatus) {
      toast.error(`Error: ${errorChangeStatus.message}`)
      if (errorChangeStatus.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorChangeStatus])

  useEffect(() => {
    if (dataAddDiscount) {
      if (
        dataAddDiscount.quotationDiscountCreate &&
        dataAddDiscount.quotationDiscountCreate.success
      ) {
        toast.success('Descuento agregado con éxito')
        formAddDiscount.resetForm()
        setModalAddDiscount(false)
      } else {
        toast.error(
          `Error: ${
            dataAddDiscount.quotationDiscountCreate?.errors?.message ||
            'No se pudo realizar descuento'
          }`
        )
      }
    }
  }, [dataAddDiscount])
  useEffect(() => {
    if (errorAddDiscount) {
      toast.error(`Error: ${errorAddDiscount.message}`)
      if (errorAddDiscount.message === ERROR_MESSAGES.SIGNATURE_EXPIRED) {
        logout()
      }
    }
  }, [errorAddDiscount])

  const getColorByStatus = (status) => {
    const OPTIONS = {
      SIN_ESTADO: 'dark',
      SIN_INTERÉS: 'dark',
      CONTINUA_INTERESADO: 'info',
      EN_FACTURACIÓN: 'info',
      FACTURADO: 'success',
      EN_SOLICITUD_DE_CRÉDITO: 'info',
      ANULADO: 'danger',
      VENCIDO: 'warning'
    }
    return OPTIONS[status] || 'secondary'
  }

  const toggleTab = () => {
    if (tab === '1') {
      setTab('2')
    }
    if (tab === '2' || tab === '3') {
      setTab('1')
    }
  }

  const toggleChangeStatus = () => {
    setModalChangeStatus((p) => !p)
  }

  const toggleComment = () => {
    setModalComment((prev) => !prev)
  }

  const toggleAddDiscount = () => {
    setModalAddDiscount((p) => !p)
  }

  const goHomeTab = () => {
    setTab('1')
  }

  document.title = 'Cotización | TOTAL SALES'

  if (loading) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/cotizaciones'
            title='Resumen cotización'
            pageTitle='Cotizaciones'
          />

          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Spinner
              style={{
                width: '3em',
                height: '3em'
              }}
              color='dark'
            />
          </Row>
        </Container>
      </div>
    )
  }

  if (!data || !user) {
    return null
  }

  if (data.listQuotations.edges.length === 0) {
    return (
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            back='/cotizaciones'
            title='Resumen cotización'
            pageTitle='Cotizaciones'
          />

          <Row
            className='justify-content-center align-items-center'
            style={{ height: '30vh' }}
          >
            <Col
              lg={12}
              className='d-flex align-items-center h-100 justify-content-center bg-white'
            >
              <div>
                <h4>Ups! No se encontró cotización 😞</h4>
                <div className='d-flex justify-content-center'>
                  <Link to='/cotizaciones' className='btn btn-info'>
                    Ir a cotizaciones
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
  /**
   * @type {{agency: {name: string, address:string, phoneNumber: string,zone: {name: string, commercialCompany: {image?: string}}},billing: null, created: string, documentNumber: string, email: string, firstName: string, firstSurname: string, id: string, phoneNumber: string, status: string, quotationType: string, credit: {id: string},quotationcommentSet: {edges: [{node:{comment: string, id:string, user: {firstName: string, lastName: string}}}]}, quotationproductthroughSet:{edges: {node: {basePrice: string, product:{image:string},priceauxquotationSet: {edges: {node: {name: string, value: string}}[]}, discountauxquotationSet: {edges: {node: {name: string, value: string}}[]}, product: {id: string, name: string, sku:string}, auxquotationdetailSet: {edges: {node:{id: string, name: string, value: string}}[]}}}[]}}}
   */
  const cotizacion = data.listQuotations.edges[0].node
  const products = cotizacion.quotationproductthroughSet.edges
  const comments = cotizacion.quotationcommentSet.edges

  const orderComments = comments.reduce((prev, current) => {
    const date = moment(current.node.created).format('DD MM YY')
    const dateFind = prev.find(({ date: datePrev }) => datePrev === date)

    if (dateFind) {
      dateFind.comments.push({
        comment: current.node.comment,
        date: current.node.created,
        user: `${current.node.user.firstName} ${current.node.user.lastName}`
      })
    } else {
      prev.push({
        date,
        comments: [
          {
            comment: current.node.comment,
            date: current.node.created,
            user: `${current.node.user.firstName} ${current.node.user.lastName}`
          }
        ]
      })
    }
    return prev
  }, [])

  // console.log('orderComments', orderComments)

  /* orderComments.forEach(({ date }) => {
    console.log(
      'date',
      `20${date.split(' ')[2]}/${date.split(' ')[1]}/${date.split(' ')[0]}`
    )
  }) */

  const isDeletPrenda =
    cotizacion.quotationType === QUOTATION_TYPES.CONTADO ||
    cotizacion.quotationType === QUOTATION_TYPES.TERCEROS

  const isMorePrices =
    cotizacion.quotationType === 'CREDITO' ||
    cotizacion.quotationType === 'TERCEROS'

  const objSett = JSON.parse(user.tokenAuth.settings)

  const htmlInner = objSett.credit_conditions

  const { quotation_hidden_pricing } = JSON.parse(user.tokenAuth.settings)

  const hiddenPrice = quotation_hidden_pricing
    ? Boolean(Number(quotation_hidden_pricing))
    : false

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          back='/cotizaciones'
          title='Resumen cotización'
          pageTitle='Cotizaciones'
          className='d-none-print'
        />
        {/*         <div style={{ height: '100vh', width: '100vw', display: 'flex' }}>
          <PDFViewer style={{ flex: 1 }}>
            <MyDocument />
          </PDFViewer>
        </div> */}

        <Row className='justify-content-center'>
          <Col xxl={9}>
            <Card id='demo'>
              <CardHeader className='d-flex justify-content-end d-none-print border-0 gap-2 flex-wrap'>
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_ANADIR_FATURACION
                ) &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.ANULADO &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.SIN_INTERES &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.EN_FACTURACION &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.FACTURADO && (
                    // eslint-disable-next-line react/jsx-indent
                    <Link
                      className='btn btn-outline-danger d-none-print'
                      to={`/agregar-facturacion/${id}`}
                    >
                      <div className='align-items-center gap-1 d-flex'>
                        <i className='mdi mdi-file-plus-outline' />
                        Añadir facturación
                      </div>
                    </Link>
                  )}
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_ANADIR_DESCUENTO
                ) &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.ANULADO &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.SIN_INTERES &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.EN_FACTURACION &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.FACTURADO && (
                    // eslint-disable-next-line react/jsx-indent
                    <button
                      className='btn btn-outline-warning d-none-print'
                      onClick={() => {
                        setModalAddDiscount(true)
                      }}
                    >
                      <div className='align-items-center gap-1 d-flex'>
                        <i className='mdi mdi-arrow-down-thin-circle-outline' />
                        Hacer descuento
                      </div>
                    </button>
                  )}
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_ANADIR_FATURACION
                ) &&
                  (STATUS_QUOATATIONS_OBJ[cotizacion.status] ===
                    STATUS_QUOATATIONS_OBJ.CONTINUA_INTERESADO ||
                    STATUS_QUOATATIONS_OBJ[cotizacion.status] ===
                      STATUS_QUOATATIONS_OBJ.SIN_ESTADO) && (
                    // eslint-disable-next-line react/jsx-indent
                    <button
                      className='btn btn-outline-dark d-none-print'
                      onClick={() => {
                        setModalChangeStatus(true)
                      }}
                    >
                      <div className='align-items-center gap-1 d-flex'>
                        <i className='mdi mdi-cog-transfer' />
                        Cambiar estado
                      </div>
                    </button>
                  )}
                {cotizacion.billing && (
                  <Link
                    className='btn btn-outline-info d-none-print'
                    to={`/facturacion/${parseId(cotizacion.billing?.id)}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='align-items-center gap-1 d-flex'>
                      <i className='mdi mdi-file-document-multiple-outline' />
                      Ver facturación
                    </span>
                  </Link>
                )}
                {cotizacion.credit && (
                  <Link
                    className='btn btn-outline-success d-none-print'
                    to={`/credito/${parseId(cotizacion.credit?.id)}`}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <span className='align-items-center gap-1 d-flex'>
                      <i className='mdi mdi-credit-card-clock-outline' />
                      Ver crédito
                    </span>
                  </Link>
                )}
                <button
                  className={`btn btn-${
                    tab === '1' ? 'info' : 'primary'
                  } d-none-print`}
                  onClick={toggleTab}
                >
                  {tab === '1' ? 'Ver comentarios' : 'Ver cotización'}
                </button>
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_ACTUALIZAR_PRECIO_QUOT
                ) &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.EN_FACTURACION &&
                  STATUS_QUOATATIONS_OBJ[cotizacion.status] !==
                    STATUS_QUOATATIONS_OBJ.FACTURADO && (
                    <>
                      <button
                        id='pricesprod'
                        className='btn btn-secondary d-none-print'
                        onClick={() => setTab('3')}
                      >
                        <i className='mdi mdi-currency-usd' />
                      </button>
                      <UncontrolledTooltip placement='left' target='pricesprod'>
                        Precios
                      </UncontrolledTooltip>
                    </>
                  )}
                {hasPermission(
                  permissions,
                  ALL_PERMISSIONS.PUEDE_CAMBIAR_COTIZACION
                ) && (
                  <>
                    <Link
                      id='addProductID'
                      className='btn btn-soft-dark d-none-print'
                      to={`/producto-a-cotizacion/${parseId(cotizacion.id)}`}
                    >
                      <i className='mdi mdi-layers-plus fs-5' />
                    </Link>
                    <UncontrolledTooltip placement='left' target='addProductID'>
                      Agregar productos
                    </UncontrolledTooltip>
                  </>
                )}
              </CardHeader>
              {tab === '1' ? (
                <CardBody>
                  <div
                    ref={(r) => {
                      if (r) contentRef.current = r
                    }}
                  >
                    <Row>
                      <Col lg={12}>
                        <CardHeader className='border-bottom-dashed p-4'>
                          <div className='d-flex justify-content-between'>
                            <div className='flex-1'>
                              <img
                                src={
                                  cotizacion.agency.zone.commercialCompany
                                    .image || '/TotalSales-Negativo.png'
                                }
                                // srcSet='/TotalSales-Positivo.png'
                                className='card-logo card-logo-dark'
                                alt='logo dark'
                                height='auto'
                                width='190'
                                style={{ maxWidth: '110px' }}
                                crossOrigin='*'
                              />
                            </div>
                            <div className='mt-sm-0 mt-3 flex-1 d-flex justify-content-end'>
                              <div className=''>
                                {/* <h6>
                          <span className='text-muted fw-normal'>
                            ID cotización:
                          </span>{' '}
                          <span id='legal-register-no'>{id}</span>
                        </h6> */}
                                <h6 className='mb-0'>
                                  <span className='text-muted fw-normal'>
                                    Ciudad:
                                  </span>{' '}
                                  <span id='email'>
                                    {cotizacion?.agency?.city || 'N/A'}
                                  </span>
                                </h6>
                                <h6 className='mb-0'>
                                  <span className='text-muted fw-normal'>
                                    Agencia:
                                  </span>{' '}
                                  <span id='email'>
                                    {cotizacion?.agency?.name || 'N/A'}
                                  </span>
                                </h6>
                                <h6 className='mb-0'>
                                  <span className='text-muted fw-normal'>
                                    Zona:
                                  </span>{' '}
                                  <span
                                    to='#'
                                    className='link-primary'
                                    id='website'
                                  >
                                    {cotizacion?.agency?.zone?.name || 'N/A'}
                                  </span>
                                </h6>
                                <h6 className='mb-0'>
                                  <span className='text-muted fw-normal'>
                                    Asesor:
                                  </span>{' '}
                                  <span
                                    to='#'
                                    className='link-primary'
                                    id='website'
                                  >
                                    {cotizacion?.user?.firstName}{' '}
                                    {cotizacion?.user?.lastName}
                                  </span>
                                </h6>
                                <h6 className='mb-0'>
                                  <span className='text-muted fw-normal'>
                                    Teléfono:
                                  </span>{' '}
                                  <span id='contact-no'>
                                    {cotizacion?.user?.profileuser
                                      ?.phoneNumber || 'N/A'}
                                  </span>
                                </h6>
                              </div>
                            </div>
                          </div>
                          <div className='mt-sm-5 mt-4'>
                            <h6 className='text-muted text-uppercase fw-semibold'>
                              {cotizacion.firstName}{' '}
                              {cotizacion.secondName || ''}{' '}
                              {cotizacion.firstSurname}{' '}
                              {cotizacion.secondSurname || ''}
                            </h6>
                            <p className='text-muted mb-1' id='address-details'>
                              {cotizacion.email}
                            </p>
                            <p className='text-muted mb-0' id='zip-code'>
                              <span>
                                CC: {cotizacion.documentNumber || 'N/A'}
                              </span>
                            </p>
                            <p className='text-muted mb-0' id='zip-code'>
                              <span>Teléfono: {cotizacion.phoneNumber}</span>
                            </p>
                          </div>
                        </CardHeader>
                      </Col>
                      <Col lg={12}>
                        <CardBody className='p-4'>
                          <Row className='g-3'>
                            <Col lg={2} xs={6}>
                              <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                ID.
                              </p>
                              <h5 className='fs-14 mb-0'>
                                <span id='invoice-no  '>{id}</span>
                              </h5>
                            </Col>
                            <Col lg={3} xs={6}>
                              <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                Fecha
                              </p>
                              <h5 className='fs-14 mb-0'>
                                <span id='invoice-date'>
                                  {moment(cotizacion.created).format(
                                    'DD MMM [del] YY'
                                  )}
                                </span>{' '}
                                <small className='text-muted' id='invoice-time'>
                                  {moment(cotizacion.created).format('hh:mm A')}
                                </small>
                              </h5>
                            </Col>
                            <Col lg={3} xs={6}>
                              <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                Estado
                              </p>
                              <span
                                className={`badge badge-soft-${getColorByStatus(
                                  cotizacion.status
                                )} fs-11`}
                                id='payment-status'
                              >
                                {cotizacion.status.replace(/_/g, ' ')}
                              </span>
                            </Col>
                            <Col lg={2} xs={6}>
                              <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                Productos
                              </p>
                              <h5 className='fs-14 mb-0'>
                                <span id='total-amount'>
                                  {
                                    cotizacion.quotationproductthroughSet.edges
                                      .length
                                  }
                                </span>
                              </h5>
                            </Col>
                            <Col lg={2} xs={6}>
                              <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                Tipo
                              </p>
                              <h5 className='fs-14 mb-0'>
                                <span id='total-amount'>
                                  {cotizacion.quotationType}
                                </span>
                              </h5>
                            </Col>
                          </Row>
                        </CardBody>
                      </Col>
                      <Col lg={12}>
                        <CardBody className='p-4'>
                          <div className='table-responsive'>
                            <Table
                              className='table text-center table-nowrap align-middle mb-0'
                              style={{ fontSize: '11px' }}
                            >
                              <thead>
                                <tr className='table-active'>
                                  <th scope='col' style={{ width: '30px' }}>
                                    #
                                  </th>
                                  <th
                                    scope='col'
                                    colSpan={2}
                                    style={{ maxWidth: '130px' }}
                                  >
                                    Producto
                                  </th>
                                  <th
                                    scope='col'
                                    style={{
                                      display: hiddenPrice ? 'none' : ''
                                    }}
                                  >
                                    Precio
                                  </th>
                                  <th
                                    scope='col'
                                    style={{
                                      maxWidth: '80px',
                                      textWrap: 'balance'
                                    }}
                                  >
                                    Otros conceptos
                                  </th>
                                  <th scope='col'>Descuentos</th>
                                  <th
                                    scope='col'
                                    className='text-end'
                                    style={{
                                      display: hiddenPrice ? 'none' : ''
                                    }}
                                  >
                                    Total
                                  </th>
                                  {isMorePrices && <th>Crédito</th>}
                                </tr>
                              </thead>
                              <tbody id='products-list'>
                                {products.map(({ node }, idx) => (
                                  <tr key={node.id}>
                                    <th scope='row'>
                                      {idx < 9 ? `0${idx + 1}` : idx + 1}
                                    </th>
                                    <td style={{ margin: 0, padding: 0 }}>
                                      <img
                                        src={node.product.image || notFoundProd}
                                        style={{
                                          maxWidth: '92px',
                                          margin: '5px 3px'
                                        }}
                                        crossOrigin='*'
                                      />
                                    </td>
                                    <td
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        maxWidth: '130px'
                                      }}
                                      className='text-start'
                                    >
                                      <div className='w-100'>
                                        <p
                                          className='fw-medium'
                                          style={{
                                            maxWidth: '100%',
                                            whiteSpace: 'break-spaces'
                                          }}
                                        >
                                          {node.product.name}
                                        </p>
                                        <p className='text-muted mb-0'>
                                          <span>SKU: </span>
                                          {node.product.sku}
                                        </p>
                                      </div>
                                      {/* <p className='text-muted mb-0'>
                                        <span>Marca: </span>
                                        {node.product.}
                                      </p> */}
                                    </td>
                                    <td
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        display: hiddenPrice
                                          ? 'none'
                                          : undefined
                                      }}
                                    >
                                      {parsePrice(node.basePrice)}
                                    </td>
                                    <td style={{ margin: 0, padding: 0 }}>
                                      {getTotal(
                                        node.priceauxquotationSet.edges,
                                        isDeletPrenda
                                          ? PRICE_TO_DELETE.PRENDA
                                          : undefined
                                      )}
                                    </td>
                                    <td style={{ margin: 0, padding: 0 }}>
                                      -
                                      {getTotal(
                                        node.discountauxquotationSet.edges,
                                        isDeletPrenda
                                          ? PRICE_TO_DELETE.PRENDA
                                          : undefined
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        margin: 0,
                                        padding: 0,
                                        display: hiddenPrice
                                          ? 'none'
                                          : undefined
                                      }}
                                      className='text-end'
                                    >
                                      {parsePrice(
                                        getFullTotalNoParse(
                                          node.basePrice,
                                          node.discountauxquotationSet.edges,
                                          node.priceauxquotationSet.edges,
                                          isDeletPrenda
                                            ? PRICE_TO_DELETE.PRENDA
                                            : null
                                        )
                                      )}
                                    </td>
                                    {isMorePrices && (
                                      <td
                                        style={{
                                          maxWidth: '135px',
                                          textAlign: 'right'
                                        }}
                                      >
                                        {node.auxquotationdetailSet.edges.map(
                                          ({ node: nodeAux }) => (
                                            <p
                                              key={nodeAux.id}
                                              className='p-0'
                                              style={{
                                                margin: '1px 3px 0px 0px'
                                              }}
                                            >
                                              <b>{nodeAux.name}:</b>{' '}
                                              <small>
                                                {parsePrice(nodeAux.value)}
                                              </small>
                                            </p>
                                          )
                                        )}
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                          {hiddenPrice ? null : (
                            <div className='border-top border-top-dashed mt-2'>
                              <Table
                                className='table table-borderless table-nowrap align-middle mb-0 ms-auto'
                                style={{ width: '250px', fontSize: '12px' }}
                              >
                                <tbody>
                                  <tr>
                                    <td>Sub Total</td>
                                    <td className='text-end'>
                                      {getAllTotal(
                                        products,
                                        isDeletPrenda
                                          ? PRICE_TO_DELETE.PRENDA
                                          : null
                                      )}
                                    </td>
                                    {isMorePrices && (
                                      <td style={{ minWidth: '95px' }} />
                                    )}
                                  </tr>
                                  <tr>
                                    <td>Otros descuentos</td>
                                    <td className='text-end'> -$0.00</td>
                                    {isMorePrices && (
                                      <td style={{ minWidth: '95px' }} />
                                    )}
                                  </tr>
                                  <tr className='border-top border-top-dashed fs-15'>
                                    <th scope='row'>Precio total</th>
                                    <th className='text-end'>
                                      {getAllTotal(products)}
                                    </th>
                                    {isMorePrices && (
                                      <th style={{ minWidth: '95px' }} />
                                    )}
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          )}

                          {htmlInner && (
                            <section
                              dangerouslySetInnerHTML={{ __html: htmlInner }}
                            />
                          )}
                          {/* <div
                          className='hstack gap-2 justify-content-end d-print-none'
                          style={{ marginTop: '3em' }}
                        >
                          {isConvert && !instance.loading && (
                            <button
                              onClick={() => window.open(urlPrint)}
                              className='btn btn-success'
                            >
                              Imprimir
                            </button>
                          )}
                          {!isConvert && (
                            <button
                              onClick={() => {
                                upload()
                                setIsConvert(true)
                              }}
                              className='btn btn-info d-flex align-items-center'
                            >
                              <i className='mdi mdi-file-pdf-box align-bottom me-1' />
                              Convertir PDF
                            </button>
                          )}
                          {instance.loading && (
                            <button
                              onClick={() => {
                                upload()
                              }}
                              className='btn btn-info'
                            >
                              <Spinner
                                size='sm'
                                className='me-1'
                                color='light'
                              />
                              Convirtiendo
                            </button>
                          )}
                          <a
                            href={instance.url}
                            target='_blank'
                            rel='noreferrer'
                            className='btn btn-primary'
                            download={`${moment().format(
                              'DD-MM-YY'
                            )}-${id}-cotizacion.pdf`}
                          >
                            Descargar
                          </a>
                        </div> */}
                        </CardBody>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              ) : tab === '2' ? (
                <CardBody>
                  <div>
                    <div className='d-sm-flex align-items-center'>
                      <h5 className='card-title flex-grow-1 mb-0'>
                        Comentarios
                      </h5>
                      <div className='flex-shrink-0 mt-2 mt-sm-0'>
                        <button
                          className='btn btn-soft-info btn-sm mt-2 mt-sm-0'
                          onClick={() => setModalComment(true)}
                        >
                          <i className='bx bx-comment align-middle me-1' />{' '}
                          Agregar comentario
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='profile-timeline'>
                    {orderComments.map(({ date, comments }) => (
                      <div className='accordion-item' key={date}>
                        <div className='' id='headingOne'>
                          <div className='accordion-button p-2 shadow-none'>
                            <div className='d-flex align-items-center'>
                              <div className='flex-shrink-0 avatar-xs'>
                                <div className='avatar-title bg-success rounded-circle'>
                                  <i className='bx bx-comment-dots' />
                                </div>
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <h6 className='fs-15 mb-0 fw-semibold'>
                                  {moment(
                                    new Date(
                                      `20${date.split(' ')[2]}-${
                                        date.split(' ')[1]
                                      }-${date.split(' ')[0]}
                                    `
                                    )
                                  ).format('DD MMM [del] YYYY')}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          id='collapseOne'
                          className='accordion-collapse'
                          // isOpen={col1}
                        >
                          {comments.map(
                            ({ comment, date: dateComment, user }, i) => (
                              <div
                                className='accordion-body ms-2 ps-5 pt-0 mb-2'
                                key={i}
                              >
                                <h6 className='mb-1'>{comment}</h6>
                                <div className='d-flex gap-1 align-items-center'>
                                  <p className='text-muted mb-0'>
                                    {moment(dateComment).format('hh:mm A')}
                                  </p>
                                  <span
                                    className='text-uppercase fs-bold d-flex align-items-center gap-1'
                                    style={{ color: '#4c76a1' }}
                                  >
                                    -
                                    <i className='ri ri-user-3-line' />
                                    {user}
                                  </span>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                    {orderComments.length > 0 && (
                      <div className='accordion-item'>
                        <div className='' id='headingOne'>
                          <div className='accordion-button p-2 shadow-none'>
                            <div className='d-flex align-items-center'>
                              <div className='flex-shrink-0 avatar-xs'>
                                <div className='avatar-title bg-success rounded-circle'>
                                  <i className='bx bx-calendar-minus' />
                                </div>
                              </div>
                              <div className='flex-grow-1 ms-3'>
                                <h6 className='fs-15 mb-0 fw-semibold'>
                                  Inicio
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {orderComments.length === 0 && (
                      <div
                        className='d-flex justify-content-center align-items-center'
                        style={{ height: '20vh' }}
                      >
                        No hay comentarios
                      </div>
                    )}
                  </div>
                </CardBody>
              ) : tab === '3' ? (
                <InfoPricesQuotation
                  products={products}
                  refetch={refetch}
                  quotationId={id}
                  goHome={goHomeTab}
                />
              ) : null}

              {tab === '1' && (
                <Row className='justify-content-center mx-2 d-none-print'>
                  <Col
                    xxl={9}
                    className='d-flex justify-content-end mb-2 d-none-print gap-2 w-100'
                  >
                    {/* <button
                      className='btn btn-info my-2 d-flex align-items-center d-none-print'
                      onClick={() => window.print()}
                      color='info'
                    >
                      Imprimir
                    </button> */}
                    <button
                      className='btn btn-success my-2 d-flex align-items-center d-none-print'
                      onClick={() => {
                        setLoadingDown(true)
                        if (contentRef.current) {
                          html2pdf()
                            .set({
                              margin: 2,
                              image: { type: 'jpeg' },
                              html2canvas: {
                                scale: 2,
                                letterRendering: true,
                                useCORS: true
                              },
                              filename: `${moment().format(
                                'DD-MM-YY'
                              )}-COT-${id}.pdf`
                            })
                            .from(contentRef.current)
                            .save()
                            .then(() => {
                              setLoadingDown(false)
                            })
                        }
                      }}
                      disabled={loadingDown}
                    >
                      {loadingDown ? (
                        <Spinner size='sm' color='light' className='me-1' />
                      ) : (
                        <i className='mdi mdi-file-download-outline me-1 d-none-print' />
                      )}
                      Descargar
                    </button>
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
        {/* <Row className='justify-content-center'>
          <Col xxl={9} className='d-flex justify-content-end mb-2'></Col>
        </Row> */}
      </Container>
      <Modal
        id='showModalComment'
        isOpen={modalComment}
        toggle={toggleComment}
        centered
        size='lg'
      >
        <ModalHeader className='bg-soft-info p-3' toggle={toggleComment}>
          Agregar comentario
        </ModalHeader>
        <Form
          className='tablelist-form'
          onSubmit={(e) => {
            e.preventDefault()
            formComment.handleSubmit()
            return false
          }}
        >
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='comment' className='form-label'>
                    Comentario
                  </Label>
                  <Input
                    rows='3'
                    name='comment'
                    id='comment'
                    className='form-control'
                    placeholder='Ingrese un comentario'
                    type='textarea'
                    onChange={formComment.handleChange}
                    onBlur={formComment.handleBlur}
                    value={formComment.values.comment}
                    invalid={Boolean(
                      formComment.touched.comment && formComment.errors.comment
                    )}
                  />
                  {formComment.errors.comment ? (
                    <FormFeedback type='invalid'>
                      {formComment.errors.comment}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setModalComment(false)
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingCreate}
              >
                {loadingCreate && <Spinner size='sm' color='light' />}
                Agregar comentario
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        id='showModalChangeStatus'
        isOpen={modalChangeStatus}
        toggle={toggleChangeStatus}
        centered
        size='lg'
      >
        <ModalHeader className='bg-soft-info p-3' toggle={toggleChangeStatus}>
          Cambiar de estado
        </ModalHeader>
        <Form
          className='tablelist-form'
          onSubmit={formChangeStatus.handleSubmit}
        >
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={12}>
                <FormGroup>
                  <Label>Estado</Label>
                  <Input
                    type='select'
                    name='status'
                    className='form-control'
                    placeholder='Seleccione el estado'
                    onChange={formChangeStatus.handleChange}
                    onBlur={formChangeStatus.handleBlur}
                    value={formChangeStatus.values.status}
                    invalid={Boolean(
                      formChangeStatus.touched.status &&
                        formChangeStatus.errors.status
                    )}
                  >
                    <option value=''>Seleccione el estado</option>
                    {STATUS_QUOATATIONS.slice(0, 3).map((status) => (
                      <option key={status[0]} value={status[0]}>
                        {status[1].replace(/_/g, ' ')}
                      </option>
                    ))}
                  </Input>
                  {formChangeStatus.errors.status && (
                    <FormFeedback type='invalid'>
                      {formChangeStatus.errors.status}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='comment' className='form-label'>
                    Comentario
                  </Label>
                  <Input
                    rows='3'
                    name='comment'
                    id='commentChangeStatus'
                    className='form-control'
                    placeholder='Ingrese un comentario'
                    type='textarea'
                    onChange={formChangeStatus.handleChange}
                    onBlur={formChangeStatus.handleBlur}
                    value={formChangeStatus.values.comment}
                    invalid={Boolean(
                      formChangeStatus.touched.comment &&
                        formChangeStatus.errors.comment
                    )}
                  />
                  {formChangeStatus.errors.comment && (
                    <FormFeedback type='invalid'>
                      {formChangeStatus.errors.comment}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
              {QUOTATION_TYPES[cotizacion.quotationType] ===
                QUOTATION_TYPES.CREDITO && (
                <Col lg={12}>
                  <div className='d-flex align-items-center justify-content-center'>
                    <Link
                      className='btn btn-danger d-flex align-items-center gap-1'
                      to={`/agregar-credito?cotizacion=${id || 0}`}
                    >
                      <i className='mdi mdi-account-cash' />
                      Solicitar crédito
                    </Link>
                  </div>
                </Col>
              )}
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setModalChangeStatus(false)
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingChangeStatus}
              >
                {loadingChangeStatus && <Spinner size='sm' color='light' />}
                Cambiar estado
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal
        id='showModalAddDiscount'
        isOpen={modalAddDiscount}
        toggle={toggleAddDiscount}
        centered
        size='lg'
      >
        <ModalHeader className='bg-soft-info p-3' toggle={toggleAddDiscount}>
          Realizar descuento
        </ModalHeader>
        <Form
          className='tablelist-form'
          onSubmit={formAddDiscount.handleSubmit}
        >
          <ModalBody>
            <input type='hidden' id='id-field' />
            <Row className='g-3'>
              <Col lg={12}>
                <Label htmlFor='name-field' className='form-label'>
                  Nombre
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <Input
                  type='text'
                  name='name'
                  id='name-field'
                  placeholder='Ingrese nombre del descuento'
                  onChange={formAddDiscount.handleChange}
                  onBlur={formAddDiscount.handleBlur}
                  value={formAddDiscount.values.name}
                  invalid={Boolean(
                    formAddDiscount.touched.name && formAddDiscount.errors.name
                  )}
                />
                {formAddDiscount.errors.name && (
                  <FormFeedback type='invalid'>
                    {formAddDiscount.errors.name}
                  </FormFeedback>
                )}
              </Col>
              <Col lg={12}>
                <Label htmlFor='discountValue' className='form-label'>
                  Valor de descuento
                  <span style={{ color: 'red' }} title='Obligatorio'>
                    *
                  </span>
                </Label>
                <InputCurrency
                  id='discountValue'
                  name='discountValue'
                  placeholder='Precio del descuento'
                  className={`form-control ${
                    formAddDiscount.touched.discountValue &&
                    formAddDiscount.errors.discountValue
                      ? 'is-invalid'
                      : ''
                  } `}
                  // prefix='$ '
                  value={formAddDiscount.values.discountValue}
                  // decimalsLimit={0}
                  // decimalSeparator=','
                  // groupSeparator='.'
                  onValueChange={(value, name) => {
                    formAddDiscount.setFieldValue(name, value || '')
                  }}
                  // allowNegativeValue={false}
                  onBlur={formAddDiscount.handleBlur}
                />
                {formAddDiscount.touched.discountValue &&
                  formAddDiscount.errors.discountValue && (
                    <div
                      style={{
                        width: '100%',
                        marginTop: '0.25rem',
                        fontSize: '0.875em',
                        color: '#f06548'
                      }}
                    >
                      {formAddDiscount.errors.discountValue}
                    </div>
                  )}
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label htmlFor='selectProductsDiscount'>
                    Producto
                    <span style={{ color: 'red' }} title='Obligatorio'>
                      *
                    </span>
                  </Label>
                  <Input
                    id='selectProductsDiscount'
                    type='select'
                    name='product'
                    className='form-control'
                    placeholder='Seleccione el producto'
                    onChange={formAddDiscount.handleChange}
                    onBlur={formAddDiscount.handleBlur}
                    value={formAddDiscount.values.product}
                    invalid={Boolean(
                      formAddDiscount.touched.product &&
                        formAddDiscount.errors.product
                    )}
                  >
                    <option value=''>Seleccione un producto</option>
                    {products.map(({ node }) => (
                      <option
                        key={node.product.id}
                        value={parseId(node.product.id)}
                      >
                        {node.product.name}
                      </option>
                    ))}
                  </Input>
                  {formAddDiscount.errors.product && (
                    <FormFeedback type='invalid'>
                      {formAddDiscount.errors.product}
                    </FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                color='light'
                onClick={() => {
                  setModalAddDiscount(false)
                }}
              >
                Cancelar{' '}
              </Button>
              <Button
                type='submit'
                color='success'
                id='add-btn'
                disabled={loadingAddDiscount}
              >
                {loadingAddDiscount && <Spinner size='sm' color='light' />}
                Realizar descuento
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  )
}

export default OneCotizacion
/* const DocumetDynamic = useMemo(() => {
    if (!dataPrint) {
      return (
        <Document>
          <Page>
            <Text>No se pudo crear la cotización</Text>
          </Page>
        </Document>
      )
    }
    /**
     * @type {{agency: {name: string, address:string, phoneNumber: string,zone: {name: string}},billing: null, created: string, documentNumber: string, email: string, firstName: string, firstSurname: string, id: string, phoneNumber: string, products: {edges: [{node: {basePrice: string, name: string, sku: string, priceSet: {edges: []}, discountSet: {edges: []}}}]}, secondName: string, secondSurname: string, status: string, quotationcommentSet: {edges: [{node:{comment: string, id:string}}]}}}

    const cotizacionPrint = dataPrint.listQuotations.edges[0].node
    const productsPrint = cotizacionPrint.products.edges
    // const commentsPrint = cotizacionPrint.quotationcommentSet.edges

    return (
      <Document title={`${moment().format('DD-MM-YY')}-${id}-cotizacion`}>
        <Page style={styles.page}>
          <View style={styles.logoContainer}>
            <Image src='/TotalSales-Positivo.png' style={styles.logo} />
            <View>
              <Text style={styles.nameTitleInfo}>
                Agencia:{' '}
                <Text style={styles.nameContentInfo}>
                  {cotizacionPrint.agency.name}
                </Text>
              </Text>
              <Text style={styles.nameTitleInfo}>
                Zona:{' '}
                <Text style={styles.nameContentInfo}>
                  {cotizacionPrint.agency.zone.name}
                </Text>
              </Text>
              <Text style={styles.nameTitleInfo}>
                Teléfono:{' '}
                <Text style={styles.nameContentInfo}>
                  {cotizacionPrint.agency.phoneNumber}
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.header}>
            <Text style={styles.name}>
              {cotizacionPrint.firstName} {cotizacionPrint.secondName}{' '}
              {cotizacionPrint.firstSurname} {cotizacionPrint.secondSurname}
            </Text>
            <Text style={styles.contact}>{cotizacionPrint.email}</Text>
            <Text style={styles.contact}>
              CC: {cotizacionPrint.documentNumber}
            </Text>
            <Text style={styles.contact}>
              Teléfono: {cotizacionPrint.phoneNumber}
            </Text>
          </View>

          <View style={styles.infoGeneral}>
            <View style={styles.infoGeneralItem}>
              <View style={styles.infoGeneralItemTitle}>
                <Text>Cotización no</Text>
              </View>
              <View style={styles.infoGeneralItemContent}>
                <Text>{parseId(cotizacionPrint.id)}</Text>
              </View>
            </View>
            <View style={styles.infoGeneralItem}>
              <View style={styles.infoGeneralItemTitle}>
                <Text>fecha</Text>
              </View>
              <View style={styles.infoGeneralItemContent}>
                <Text>
                  {moment(cotizacionPrint.created).format('DD MMM [del] YY')}{' '}
                  <Text style={{ color: 'gray' }}>
                    {moment(cotizacionPrint.created).format('hh:mm A')}
                  </Text>
                </Text>
              </View>
            </View>
            <View style={styles.infoGeneralItem}>
              <View style={styles.infoGeneralItemTitle}>
                <Text>estado</Text>
              </View>
              <View style={styles.infoGeneralItemContent}>
                <Text
                  style={{
                    backgroundColor: getColorByStatusPrint(
                      cotizacionPrint.status
                    ),
                    padding: '4px',
                    borderRadius: '4px',
                    textAlign: 'center'
                  }}
                >
                  {cotizacionPrint.status.replace(/_/g, ' ')}
                </Text>
              </View>
            </View>
            <View style={styles.infoGeneralItem}>
              <View style={styles.infoGeneralItemTitle}>
                <Text>productos</Text>
              </View>
              <View style={styles.infoGeneralItemContent}>
                <Text>{productsPrint.length}</Text>
              </View>
            </View>
          </View>

          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <View style={{ marginRight: '5px' }}>
                <Text>#</Text>
              </View>
              <View style={styles.itemTable}>
                <Text>Producto</Text>
              </View>
              <View style={styles.itemTable}>
                <Text>Precio</Text>
              </View>
              <View style={styles.itemTable}>
                <Text>Otros conceptos</Text>
              </View>
              <View style={styles.itemTable}>
                <Text>Descuentos</Text>
              </View>
              <View style={styles.itemTable}>
                <Text>Total</Text>
              </View>
            </View>
            <View style={styles.tableBody}>
              {productsPrint.map(({ node }, idx) => (
                <View style={styles.row} key={node.id}>
                  <View style={{ marginRight: '5px' }}>
                    <Text>{idx < 9 ? `0${idx + 1}` : idx + 1}</Text>
                  </View>
                  <View style={styles.itemTable}>
                    <Text>{node.name}</Text>
                    <Text style={{ color: 'gray' }}>{node.sku}</Text>
                  </View>
                  <View style={styles.itemTable}>
                    <Text>{parsePrice(node.basePrice)}</Text>
                  </View>
                  <View style={styles.itemTable}>
                    <Text>{getTotal(node.priceSet.edges)}</Text>
                  </View>
                  <View style={styles.itemTable}>
                    <Text>-{getTotal(node.discountSet.edges)}</Text>
                  </View>
                  <View style={styles.itemTable}>
                    <Text>
                      {getFullTotal(
                        node.basePrice,
                        node.discountSet.edges,
                        node.priceSet.edges
                      )}
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <View style={styles.secondTable}>
            <View style={styles.secondTBody}>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '0.5cm',
                  padding: '7px 4px'
                }}
              >
                <Text>Sub Total</Text>
                <Text style={{ textAlign: 'right' }}>
                  {getAllTotal(productsPrint)}
                </Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '0.5cm',
                  padding: '7px 4px',
                  fontWeight: 100
                }}
              >
                <Text>Otros descuentos</Text>
                <Text style={{ textAlign: 'right' }}> -$0.00</Text>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  gap: '0.5cm',
                  padding: '7px 4px',
                  borderTop: '1px dashed #dcdcdc',
                  fontWeight: 900,
                  color: '#000',
                  fontSize: '14px'
                }}
              >
                <Text style={{ fontWeight: 900 }}>Precio total</Text>
                <Text style={{ textAlign: 'right' }}>
                  {getAllTotal(productsPrint)}
                </Text>
              </View>
            </View>
          </View>

          <View style={styles.comments}>
            <Text style={{ marginBottom: '10px' }}>COMENTARIOS:</Text>
            <View>
              {commentsPrint.map(({ node }) => (
                <View
                  key={node.id}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: '6px',
                    marginTop: '8px',
                    marginBottom: '8px'
                  }}
                >
                  <Text style={{ marginRight: '2px' }}>•</Text>{' '}
                  <Text style={{ color: '#444' }}>{node.comment}</Text>
                </View>
              ))}
              {commentsPrint.length === 0 && (
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: '6px',
                    marginTop: '8px',
                    marginBottom: '8px'
                  }}
                >
                  <Text style={{ color: '#444', textAlign: 'center' }}>
                    No hay comentarios
                  </Text>
                </View>
              )}
            </View>
          </View>
        </Page>
      </Document>
    )
  }, [dataPrint]) */

// const [instance, upload] = usePDF({ document: DocumetDynamic })

/* useEffect(() => {
    setUrlPrint(instance.url)
  }, [instance.url, instance.loading]) */

/* const openWindow = (work) => {
    console.log('urlPrint INSIDE', urlPrint)
    console.log('instance.url INDIE', instance.url)
    if (work) {
      window.open(urlPrint)
    }
  } */

/* useEffect(() => {
    openWindow(false)
  }, [urlPrint]) */
