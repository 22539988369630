/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
  FormGroup,
  Alert
} from 'reactstrap'
import Select from 'react-select'
// import TableContainer from '../../../Components/Common/TableContainer'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  getFormParse,
  getFullTotal,
  getFullTotalNoParse,
  hasPermission,
  parseId,
  parsePrice
} from '../../../helpers'
import { toast } from 'react-toastify'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  ALL_PERMISSIONS,
  CREDIT_TYPES,
  TYPES_COTIZ_CREATE
} from '../../../helpers/consts'
import { useGetUser } from '../../../hooks/user'
import { CREATE_CREDIT } from '../../../graphql/mutations/creditMutations'
import {
  GET_FORM_CREDIT_ENTITY,
  GET_PRE_CREDITS,
  GET_QUOTATION_TO_CREDIT
} from '../../../graphql/queries/getCredits'
import { FormDinamyc } from '../../../Components/Own/creditPage/FormDinamyc'
import { InputCurrency } from '../../../Components/Own/InputCurrency'
import { GET_QUOTATION_ALIADO_TO_CREDIT } from '../../../graphql/queries/getCotizacionAliados'
import { BadgeStatusCotAl } from '../../../Components/Own/cotizacionAliados/BadgeStatusCotAl'
import moment from 'moment'
import { ModalInfoPreCredit } from '../../../Components/Own/creditPage/ModalInfoPreCredit'
import { useDisclosure } from '../../../hooks/useDisclosure'
// import { DefaultColumnFilter } from '../../../Components/Common/filters'

// import TableContainer from '../../../Components/Common/TableContainer'
// import { Type, Quantity, OrderValue, AvgPrice, Price, Status } from './OrderCol'

const initialValues = {
  documentNumber: '',
  documentType: '',
  expeditionDate: '',
  firstName: '',
  secondName: '',
  firstSurname: '',
  secondSurname: '',
  dateBirth: '',
  email: '',
  phoneNumber: '',
  creditType: '',
  // if creditType is Tradicional
  initialFee: '',
  term: 1,
  productValue: ''
}

const INIT_ARR_TYPES_DOC = [
  {
    value: 'CC',
    label: 'Cédula ciudadanía',
    color: '#5243AA'
  },
  {
    value: 'CE',
    label: 'Cédula extranjera',
    color: '#5243AA'
  },
  {
    value: 'NIT',
    label: 'NIT',
    color: '#2243AA'
  }
]

export default function AddCreditPage() {
  const [queryParam] = useSearchParams()
  const idQuotation = queryParam.get('cotizacion')
  const idQuotationAliado = queryParam.get('cotizacion-aliado')

  const creditLine = idQuotation
    ? TYPES_COTIZ_CREATE.MOTOCICLETAS
    : idQuotationAliado
    ? TYPES_COTIZ_CREATE.LIBRE_INVERSION
    : undefined

  const [
    gqlCreateCredit,
    { data: dataCreate, loading: loadingCreate, error: errorCreate }
  ] = useMutation(CREATE_CREDIT)
  const [
    gqlGetCotizacion,
    { data: dataQuotation, loading: loadingQuotation, error: errorQuotation }
  ] = useLazyQuery(GET_QUOTATION_TO_CREDIT, { fetchPolicy: 'no-cache' })
  const [
    gqlGetCotizacionAliado,
    {
      data: dataQuotationAliado,
      loading: loadingQuotationAliado,
      error: errorQuotationAliado
    }
  ] = useLazyQuery(GET_QUOTATION_ALIADO_TO_CREDIT, { fetchPolicy: 'no-cache' })

  const [
    gqlGetPreCredits,
    { data: dataPreCredit, loading: loadingPreCredit, error: errorPreCredit }
  ] = useLazyQuery(GET_PRE_CREDITS, { fetchPolicy: 'no-cache' })

  const [
    gqlGetForm,
    { data: dataForm, loading: loadingForm, error: errorForm }
  ] = useLazyQuery(GET_FORM_CREDIT_ENTITY, { fetchPolicy: 'no-cache' })

  const navigate = useNavigate()

  const { permissions, user } = useGetUser()
  const maxTerm = JSON.parse(user.tokenAuth.settings)?.max_quotation_fee || 24

  /**
   * @typedef {{node: {basePrice: string, product: {name: string, image: string, id: string}, priceauxquotationSet: {edges: {node: {name: string, value: string}}[]}, discountauxquotationSet: {edges: {node: {name: string, value: string}}[]}}}} product
   * @typedef {product[]} IProducst
   */

  /** @type {[IProducst, React.Dispatch<React.SetStateAction<IProducst>>]} */
  const [productsQuot, setProductsQuot] = useState([])
  /** @type {[{name: string, id: string, value: string, description: string}[], React.Dispatch<React.SetStateAction<[]>>]} */
  const [productsSelected, setProductsSelected] = useState([])
  const [formDynamicValues, setFormDynamicValues] = useState(null)

  const [productAliadoSelected, setProductAliadoSelected] = useState(null)

  const [typeDocumentsArr, setTypeDocumentsArr] = useState(INIT_ARR_TYPES_DOC)

  const {
    isOpen: isOpenPreCredit,
    onClose: onClosePreCredit,
    onOpen: onOpenPreCredit
  } = useDisclosure()

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      documentNumber: Yup.string()
        .required('El número de documento es obligatorio')
        .matches(/^\d+$/, 'El número de documento debe contener solo números'),
      documentType: Yup.string()
        .oneOf(['CC', 'CE', 'NIT'], 'Tipo de documento inválido')
        .required('El tipo de documento es obligatorio'),
      expeditionDate: Yup.date()
        .max(new Date(), 'Fecha no válida')
        .when('dateBirth', (dateBirth, schema) => {
          if (!dateBirth) return schema
          const minDate = new Date(dateBirth)
          minDate.setFullYear(minDate.getFullYear() + 18)
          return schema.min(minDate, 'La fecha de expedición no es posible.')
        })
        .required('La fecha de expedición es obligatoria'),
      firstName: Yup.string().required('El primer nombre es obligatorio'),
      secondName: Yup.string(),
      firstSurname: Yup.string().required('El primer apellido es obligatorio'),
      secondSurname: Yup.string(),
      dateBirth: Yup.date()
        .max(
          new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
          'Debes ser mayor de edad'
        )
        .required('La fecha de nacimiento es obligatoria'),
      email: Yup.string()
        .email('El correo electrónico no es válido')
        .required('El correo electrónico es obligatorio'),
      phoneNumber: Yup.string().required(
        'El número de teléfono es obligatorio'
      ),
      creditType: Yup.string()
        .oneOf(['INMEDIATO', 'TRADICIONAL'], 'Tipo de crédito inválido')
        .required('El tipo de crédito es obligatorio'),
      productValue: Yup.string()
        .matches(/^[0-9.,]+$/, 'Debe ser un número válido')
        .test(
          'not-zero',
          'Debe ser mayor que 0',
          (value) => parseFloat(value) !== 0
        )
        .when('creditType', {
          is: 'TRADICIONAL',
          then: Yup.string().required('Campo obligatorio')
        }),
      initialFee: Yup.string()
        .matches(/^[0-9.,]+$/, 'Debe ser un número válido')
        .when('creditType', {
          is: 'TRADICIONAL',
          then: Yup.string().required('Campo obligatorio')
        }),
      term: Yup.number()
        .min(1, 'Debe ser mayor a cero')
        .max(maxTerm, `Las cuotas no pueden ser mayor a ${maxTerm}`)
        .when('creditType', {
          is: 'TRADICIONAL',
          then: Yup.number().required('Campo obligatorio')
        })
    }),
    onSubmit: (values) => {
      const input = {
        ...values,
        creditLine
      }
      if (
        idQuotation &&
        Number(idQuotation) &&
        dataQuotation &&
        dataQuotation.listQuotations
      ) {
        input.quotationId = idQuotation
        input.products = productsSelected.map(
          ({ description, name, value }) => ({ description, name, value })
        )
      }
      if (
        idQuotationAliado &&
        Number(idQuotationAliado) &&
        dataQuotationAliado &&
        productAliadoSelected
      ) {
        input.allyQuoteId = parseId(productAliadoSelected.id)
        input.products = [
          {
            name: productAliadoSelected.name,
            value: productAliadoSelected.value,
            description: productAliadoSelected.description
          }
        ]
      }
      if (input.creditType === CREDIT_TYPES.TRADICIONAL) {
        input.form = { forms: formDynamicValues }
      } else {
        delete input.productValue
        delete input.term
        delete input.initialFee
        delete input.form
      }
      gqlCreateCredit({
        variables: {
          input
        }
      })
    }
  })

  useEffect(() => {
    if (!hasPermission(permissions, ALL_PERMISSIONS.ANADIR_CREDITO)) {
      navigate('/')
    }
  }, [permissions])

  useEffect(() => {
    if (!idQuotation) return
    const idNum = Number(idQuotation)
    if (isNaN(idNum)) return

    gqlGetCotizacion({
      variables: {
        id: idNum
      }
    })
  }, [idQuotation])
  useEffect(() => {
    if (!idQuotationAliado) return
    const idNum = Number(idQuotationAliado)
    if (isNaN(idNum)) return

    gqlGetCotizacionAliado({
      variables: {
        id: idNum
      }
    })
  }, [idQuotationAliado])

  useEffect(() => {
    if (dataCreate) {
      if (dataCreate.creditCreate.success) {
        toast.success('Crédito creado con éxito')
        if (dataCreate.creditCreate.url) {
          window.location.replace(dataCreate.creditCreate.url)
          return
        }
        navigate(`/credito/${parseId(dataCreate.creditCreate.credit.id)}`)
      } else {
        toast.error(`Error ${dataCreate.creditCreate.errors.message}`)
      }
    }
  }, [dataCreate])

  useEffect(() => {
    if (errorCreate) {
      toast.error(`ERROR: ${errorCreate.message}`)
    }
  }, [errorCreate])

  useEffect(() => {
    if (errorQuotation) {
      toast.error(`ERROR: ${errorQuotation.message}`)
    }
  }, [errorQuotation])
  useEffect(() => {
    if (errorQuotationAliado) {
      toast.error(`ERROR: ${errorQuotationAliado.message}`)
    }
  }, [errorQuotationAliado])
  useEffect(() => {
    if (errorForm) {
      toast.error(`ERROR: ${errorForm.message}`)
    }
  }, [errorForm])
  useEffect(() => {
    if (errorPreCredit) {
      toast.error(`ERROR: ${errorPreCredit.message}`)
    }
  }, [errorPreCredit])

  useEffect(() => {
    if (!dataQuotation) return
    /** @type {{documentNumber: string, email: string, firstName: string, firstSurname: string, secondName: string, secondSurname: string, phoneNumber: string, quotationproductthroughSet: {edges: {node: {id: string, basePrice: string}}[]}}} */
    const quotation = dataQuotation.listQuotations.edges[0].node
    if (quotation) {
      formik.setValues({
        documentNumber: quotation.documentNumber,
        documentType: '',
        expeditionDate: '',
        firstName: quotation.firstName,
        secondName: quotation.secondName,
        firstSurname: quotation.firstSurname,
        secondSurname: quotation.secondSurname,
        dateBirth: '',
        email: quotation.email,
        phoneNumber: quotation.phoneNumber,
        creditType: ''
      })
      if (quotation.quotationproductthroughSet.edges.length > 0) {
        setProductsQuot(quotation.quotationproductthroughSet.edges)
      }
    }
  }, [dataQuotation])

  useEffect(() => {
    if (!dataQuotationAliado) return
    /** @type {{documentNumber: string, email: string, firstName: string, firstSurname: string, secondName: string, secondSurname: string, phoneNumber: string, quotationproductthroughSet: {edges: {node: {id: string, basePrice: string}}[]}}} */
    const quotation = dataQuotationAliado.listAllyQuote.edges[0].node
    if (quotation) {
      formik.setValues({
        documentNumber: quotation.documentNumber || '',
        documentType: '',
        expeditionDate: '',
        firstName: quotation.firstName,
        secondName: quotation.secondName,
        firstSurname: quotation.firstSurname,
        secondSurname: quotation.secondSurname,
        dateBirth: '',
        email: quotation.email || '',
        phoneNumber: quotation.phoneNumber || '',
        creditType: ''
      })
    }
  }, [dataQuotationAliado])

  useEffect(() => {
    if (formik.values.creditType === CREDIT_TYPES.TRADICIONAL) {
      gqlGetForm({
        variables: { entity: 'CREDITO' }
      })
    }
  }, [formik.values.creditType])

  useEffect(() => {
    if (!productsSelected || productsSelected.length === 0) return
    let tot = 0
    productsSelected.forEach(({ value }) => {
      tot += Number(value)
    })
    formik.setFieldValue('productValue', tot)
  }, [productsSelected])

  useEffect(() => {
    if (!productAliadoSelected) return
    formik.setFieldValue('productValue', productAliadoSelected.value)
    if (productAliadoSelected.initial) {
      formik.setFieldValue('initialFee', productAliadoSelected.initial)
    }
  }, [productAliadoSelected])

  const getTotal = (entity) => {
    let tot = 0
    entity.forEach(({ node }) => {
      tot += Number(node.value)
    })

    return tot
  }

  const handleSearchPreCredits = () => {
    const { documentNumber, documentType } = formik.values
    if (!documentNumber || !documentType) return
    gqlGetPreCredits({
      variables: {
        documentNumber,
        documentType
      }
    })
  }

  // console.log('formik.', formik.errors.productValue)
  // console.log('formik value prouct.', formik.values.productValue)
  document.title = 'Crear Crédito | TOTAL SALES'

  if (!user) return null

  const { quotation_hidden_pricing } = JSON.parse(user.tokenAuth.settings)

  const hiddenPrice = quotation_hidden_pricing
    ? Boolean(Number(quotation_hidden_pricing))
    : false

  return (
    <div className='page-content'>
      <Container fluid>
        <BreadCrumb
          title='Crear crédito'
          pageTitle='Créditos'
          back='/creditos'
        />
        <Row>
          <Col lg={12}>
            {dataPreCredit && dataPreCredit.oldCreditByCustomer.length > 0 && (
              <Alert>
                <div className='d-flex align-items-center justify-content-between'>
                  <p className='m-0 p-0'>
                    Este usuario ya tiene crédito o es codeudor
                  </p>
                  <button
                    className='border-0 p-0 bg-transparent text-info'
                    style={{ textDecoration: 'underline' }}
                    onClick={onOpenPreCredit}
                  >
                    Ver más
                  </button>
                </div>
              </Alert>
            )}
            <Card>
              <CardHeader className='d-flex align-items-center border-0 gap-3 flex-md-column flex-lg-row flex-sm-column '>
                <h5 className='card-title mb-0 d-block'>
                  Información de cliente{' '}
                  {loadingPreCredit && <Spinner size='sm' />}
                </h5>
                {loadingQuotation && <Spinner size='sm' color='info' />}
                {loadingQuotationAliado && <Spinner size='sm' color='info' />}
              </CardHeader>
              <Form
                autoComplete='off'
                onSubmit={(e) => {
                  e.preventDefault()
                  const values = Object.fromEntries(new FormData(e.target))
                  const arrayFinal = getFormParse(values)
                  if (arrayFinal.length > 0) {
                    setFormDynamicValues(arrayFinal)
                  }
                  if (
                    formik.errors.initialFee ||
                    formik.errors.productValue ||
                    formik.errors.term
                  ) {
                    formik.setFieldTouched('initialFee', true)
                    formik.setFieldTouched('productValue', true)
                    formik.setFieldTouched('term', true)
                    return
                  }
                  formik.handleSubmit(e)
                }}
              >
                <CardBody>
                  {/* <input type='hidden' id='id-field' /> */}
                  <Row className='g-3'>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='firstName-field' className='form-label'>
                          Nombre
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='text'
                          name='firstName'
                          id='firstName-field'
                          placeholder='Ingrese su primer nombre'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstName}
                          invalid={Boolean(
                            formik.touched.firstName && formik.errors.firstName
                          )}
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <FormFeedback>{formik.errors.firstName}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='secondName-field'
                          className='form-label'
                        >
                          Segundo nombre
                        </Label>
                        <Input
                          type='text'
                          name='secondName'
                          id='secondName-field'
                          placeholder='Ingrese su segundo nombre'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.secondName}
                          invalid={Boolean(
                            formik.touched.secondName &&
                              formik.errors.secondName
                          )}
                        />
                        {formik.touched.secondName &&
                        formik.errors.secondName ? (
                          <FormFeedback>
                            {formik.errors.secondName}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='firstSurname-field'
                          className='form-label'
                        >
                          Apellido
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='text'
                          name='firstSurname'
                          id='firstSurname-field'
                          placeholder='Ingrese su primer apellido'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.firstSurname}
                          invalid={Boolean(
                            formik.touched.firstSurname &&
                              formik.errors.firstSurname
                          )}
                        />
                        {formik.touched.firstSurname &&
                        formik.errors.firstSurname ? (
                          <FormFeedback>
                            {formik.errors.firstSurname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='secondSurname-field'
                          className='form-label'
                        >
                          Segundo apellido
                        </Label>
                        <Input
                          type='text'
                          name='secondSurname'
                          id='secondSurnname-field'
                          placeholder='Ingrese su segundo apellido'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.secondSurname}
                          invalid={Boolean(
                            formik.touched.secondSurname &&
                              formik.errors.secondSurname
                          )}
                        />
                        {formik.touched.secondSurname &&
                        formik.errors.secondSurname ? (
                          <FormFeedback>
                            {formik.errors.secondSurname}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <FormGroup>
                        <Label
                          htmlFor='documentType-field'
                          className='form-label'
                        >
                          Tipo de documento
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Select
                          className='basic-single'
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? 'grey'
                                : formik.touched.documentType &&
                                  formik.errors.documentType
                                ? 'red'
                                : '#ced4da'
                            })
                          }}
                          placeholder='Seleccione tipo de documento'
                          classNamePrefix='select'
                          isClearable
                          isSearchable
                          name='documentType'
                          onChange={(e) => {
                            if (!e) {
                              return formik.setFieldValue('documentType', '')
                            }
                            formik.setFieldValue('documentType', e.value)
                          }}
                          onBlur={() => {
                            formik.setFieldTouched('documentType', true)
                            handleSearchPreCredits()
                          }}
                          options={typeDocumentsArr}
                        />
                        {formik.touched.documentType &&
                          formik.errors.documentType && (
                            <div
                              style={{
                                width: '100%',
                                marginTop: '0.25rem',
                                fontSize: '0.875em',
                                color: '#f06548'
                              }}
                            >
                              {formik.errors.documentType}
                            </div>
                          )}
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='documentNumber-field'
                          className='form-label'
                        >
                          Número de documento
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='text'
                          name='documentNumber'
                          id='documentNumber-field'
                          placeholder='Ingrese su número de documento'
                          onChange={formik.handleChange}
                          onBlur={(e) => {
                            formik.handleBlur(e)
                            handleSearchPreCredits()
                          }}
                          value={formik.values.documentNumber}
                          invalid={Boolean(
                            formik.touched.documentNumber &&
                              formik.errors.documentNumber
                          )}
                        />
                        {formik.touched.documentNumber &&
                        formik.errors.documentNumber ? (
                          <FormFeedback>
                            {formik.errors.documentNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='phoneNumber-field'
                          className='form-label'
                        >
                          Número de teléfono
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='tel'
                          name='phoneNumber'
                          id='phoneNumber-field'
                          placeholder='Ingrese su número de teléfono'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                          invalid={Boolean(
                            formik.touched.phoneNumber &&
                              formik.errors.phoneNumber
                          )}
                        />
                        {formik.touched.phoneNumber &&
                        formik.errors.phoneNumber ? (
                          <FormFeedback>
                            {formik.errors.phoneNumber}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label htmlFor='email-field' className='form-label'>
                          Correo electrónico
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='email'
                          name='email'
                          id='email-field'
                          placeholder='Ingrese su correo electrónico'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          invalid={Boolean(
                            formik.touched.email && formik.errors.email
                          )}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label htmlFor='dateBirth-field' className='form-label'>
                          Fecha de nacimiento
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='date'
                          name='dateBirth'
                          id='dateBirth-field'
                          placeholder='Ingrese fecha de nacimiento'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.dateBirth}
                          invalid={Boolean(
                            formik.touched.dateBirth && formik.errors.dateBirth
                          )}
                        />
                        {formik.touched.dateBirth && formik.errors.dateBirth ? (
                          <FormFeedback>{formik.errors.dateBirth}</FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='expeditionDate-field'
                          className='form-label'
                        >
                          Fecha de expedición
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Input
                          type='date'
                          name='expeditionDate'
                          id='expeditionDate-field'
                          placeholder='Ingrese fecha de expedición'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.expeditionDate}
                          invalid={Boolean(
                            formik.touched.expeditionDate &&
                              formik.errors.expeditionDate
                          )}
                        />
                        {formik.touched.expeditionDate &&
                        formik.errors.expeditionDate ? (
                          <FormFeedback>
                            {formik.errors.expeditionDate}
                          </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col lg={6}>
                      <div>
                        <Label
                          htmlFor='quotationType-field'
                          className='form-label'
                        >
                          Tipo de crédito
                          <span style={{ color: 'red' }} title='Obligatorio'>
                            *
                          </span>
                        </Label>
                        <Select
                          className='basic-single'
                          classNamePrefix='select'
                          isClearable
                          isSearchable
                          styles={{
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderColor: state.isFocused
                                ? 'grey'
                                : formik.touched.creditType &&
                                  formik.errors.creditType
                                ? 'red'
                                : '#ced4da'
                            })
                          }}
                          placeholder='Seleccione tipo de crédito'
                          name='creditType'
                          options={[
                            {
                              value: 'INMEDIATO',
                              label: 'Inmediato',
                              color: '#5243AA'
                            },
                            {
                              value: 'TRADICIONAL',
                              label: 'Tradicional',
                              color: '#5243AA'
                            }
                          ]}
                          onChange={(e) => {
                            if (!e) {
                              return formik.setFieldValue('creditType', '')
                            }
                            formik.setFieldValue('creditType', e.value)
                            if (e.value === 'INMEDIATO') {
                              if (formik.values.documentType === 'NIT') {
                                formik.setFieldValue('documentType', '')
                              }
                              setTypeDocumentsArr(
                                INIT_ARR_TYPES_DOC.slice(0, 2)
                              )
                            } else {
                              if (typeDocumentsArr.length < 3) {
                                setTypeDocumentsArr(INIT_ARR_TYPES_DOC)
                              }
                            }
                          }}
                          onBlur={() =>
                            formik.setFieldTouched('creditType', true)
                          }
                        />
                        {formik.touched.creditType &&
                        formik.errors.creditType ? (
                          <div
                            style={{
                              width: '100%',
                              marginTop: '0.25rem',
                              fontSize: '0.875em',
                              color: '#f06548'
                            }}
                          >
                            {formik.errors.creditType}
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    {formik.values.creditType === CREDIT_TYPES.TRADICIONAL &&
                      (loadingForm || !dataForm || !dataForm.getEntiyForm ? (
                        <Col lg={12}>
                          <Spinner />
                        </Col>
                      ) : (
                        <Col lg={12} className='mt-4'>
                          <FormDinamyc
                            namtrikForm={JSON.parse(dataForm.getEntiyForm)}
                          />
                        </Col>
                      ))}

                    {productsQuot.length > 0 && (
                      <Col
                        lg={12}
                        className='p-4'
                        style={{ backgroundColor: '#f3f3f9' }}
                      >
                        <h5 className='text-center mb-2'>
                          Productos de la cotizacion
                        </h5>
                        <Row>
                          {productsQuot.map(({ node }) => (
                            <Col lg={4} key={node.id}>
                              <Card className='pricing-box ribbon-box right'>
                                {productsSelected.find(
                                  ({ id }) => id === node.id
                                ) ? (
                                  <div className='ribbon-two ribbon-two-success'>
                                    <span>Selec.</span>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <CardBody className='p-4 m-2'>
                                  <div className='d-flex align-items-center'>
                                    <div className='flex-grow-1'>
                                      <h5 className='mb-1 fw-semibold'>
                                        {node.product.name}
                                      </h5>
                                      <p className='text-muted mb-0'>
                                        {node.product.sku}
                                      </p>
                                    </div>
                                    <div className='avatar-sm'>
                                      <div className='rounded-circle text-primary'>
                                        {node.product.image ? (
                                          <img
                                            src={node.product.image}
                                            style={{
                                              width: '100%',
                                              height: '100%',
                                              borderRadius: '100%'
                                            }}
                                          />
                                        ) : (
                                          node.product.name.charAt()
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {hiddenPrice ? null : (
                                    <div className='pt-4'>
                                      <h2>{parsePrice(node.basePrice)}</h2>
                                    </div>
                                  )}
                                  <hr className='my-4 text-muted' />
                                  <div>
                                    <ul className='list-unstyled text-muted vstack gap-3'>
                                      <li>
                                        <div className='d-flex align-items-center'>
                                          <div className='flex-shrink-0 text-success me-1'>
                                            <i className='mdi mdi-plus-circle fs-15 align-middle' />
                                          </div>
                                          <div className='flex-grow-1'>
                                            Otros precios:{' '}
                                            <b>
                                              {parsePrice(
                                                getTotal(
                                                  node.priceauxquotationSet
                                                    .edges
                                                )
                                              )}
                                            </b>
                                          </div>
                                        </div>
                                      </li>
                                      <li>
                                        <div className='d-flex align-items-center'>
                                          <div className='flex-shrink-0 text-danger me-1'>
                                            <i className='mdi mdi-minus-circle fs-15 align-middle' />
                                          </div>
                                          <div className='flex-grow-1'>
                                            Descuentos:{' '}
                                            <b>
                                              {parsePrice(
                                                getTotal(
                                                  node.discountauxquotationSet
                                                    .edges
                                                )
                                              )}
                                            </b>
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                    <hr className='my-4 text-muted' />
                                    {hiddenPrice ? null : (
                                      <div className='d-flex align-items-center gap-2'>
                                        <h5 className='p-0 m-0'>Total: </h5>
                                        <p className='fs-5 fw-bold p-0 m-0'>
                                          {getFullTotal(
                                            node.basePrice,
                                            node.discountauxquotationSet.edges,
                                            node.priceauxquotationSet.edges
                                          )}
                                        </p>
                                      </div>
                                    )}
                                    <div className='mt-4'>
                                      {productsSelected.find(
                                        ({ id }) => id === node.id
                                      ) ? (
                                        <Button
                                          className='btn btn-soft-danger w-100 waves-effect waves-light'
                                          onClick={() => {
                                            setProductsSelected((prev) => {
                                              const newArr = [...prev]
                                              return newArr.filter(
                                                ({ id }) => id !== node.id
                                              )
                                            })
                                          }}
                                        >
                                          <i className='mdi mdi-trash-can-outline' />
                                          Eliminar
                                        </Button>
                                      ) : (
                                        <Button
                                          className='btn btn-soft-info w-100 waves-effect waves-light'
                                          onClick={() => {
                                            setProductsSelected((prev) => [
                                              ...prev,
                                              {
                                                name: node.product.name,
                                                id: node.id,
                                                value: getFullTotalNoParse(
                                                  node.basePrice,
                                                  node.discountauxquotationSet
                                                    .edges,
                                                  node.priceauxquotationSet
                                                    .edges
                                                ),
                                                description:
                                                  'Producto desde cotización'
                                              }
                                            ])
                                          }}
                                        >
                                          <i className='mdi mdi-check-circle-outline' />
                                          Seleccionar
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    )}
                    {dataQuotationAliado &&
                      dataQuotationAliado.listAllyQuote?.edges.length > 0 && (
                        <Col
                          lg={12}
                          className='p-4'
                          style={{ backgroundColor: '#f3f3f9' }}
                        >
                          <h5 className='text-center mb-2'>
                            Producto de la cotización de aliado
                          </h5>
                          <Row>
                            {dataQuotationAliado.listAllyQuote?.edges.map(
                              ({ node }) => (
                                <Col lg={4} key={node.id}>
                                  <Card className='pricing-box ribbon-box right'>
                                    {productAliadoSelected?.id === node.id ? (
                                      <div className='ribbon-two ribbon-two-success'>
                                        <span>Selec.</span>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                    <CardBody className='p-4 m-2'>
                                      <div className='d-flex align-items-center'>
                                        <div className='flex-grow-1'>
                                          <h5 className='mb-1 fw-semibold'>
                                            {node.product}
                                          </h5>
                                          <p className='text-muted mb-0'>
                                            <BadgeStatusCotAl
                                              status={node.status}
                                            />
                                          </p>
                                        </div>
                                      </div>
                                      <div className='pt-4'>
                                        <h2>{parsePrice(node.productValue)}</h2>
                                      </div>
                                      <hr className='my-4 text-muted' />
                                      <div>
                                        <ul className='list-unstyled text-muted vstack gap-3'>
                                          <li>
                                            <div className='d-flex align-items-center'>
                                              <div className='flex-shrink-0 text-success me-1'>
                                                <i className='mdi mdi-plus-circle fs-15 align-middle' />
                                              </div>
                                              <div className='flex-grow-1'>
                                                Inicial:{' '}
                                                <b>
                                                  {parsePrice(node.initialFee)}
                                                </b>
                                              </div>
                                            </div>
                                          </li>
                                          <li>
                                            <div className='d-flex align-items-center'>
                                              <div className='flex-shrink-0 text-danger me-1'>
                                                <i className='mdi mdi-clock-outline fs-15 align-middle' />
                                              </div>
                                              <div className='flex-grow-1'>
                                                Creado:{' '}
                                                <b>
                                                  {moment(node.created).format(
                                                    'DD MMM [de] YYYY'
                                                  )}
                                                </b>
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                        <hr className='my-4 text-muted' />
                                        {/* <div className='d-flex align-items-center gap-2'>
                                          <h5 className='p-0 m-0'>Total: </h5>
                                          <p className='fs-5 fw-bold p-0 m-0'>
                                            {getFullTotal(
                                              node.productValue,
                                              node.discountauxquotationSet
                                                .edges,
                                              node.priceauxquotationSet.edges
                                            )}
                                          </p>
                                        </div> */}
                                        <div className='mt-4'>
                                          {productAliadoSelected?.id ===
                                          node.id ? (
                                            <Button
                                              className='btn btn-soft-danger w-100 waves-effect waves-light'
                                              onClick={() => {
                                                setProductAliadoSelected(null)
                                              }}
                                            >
                                              <i className='mdi mdi-trash-can-outline' />
                                              Eliminar
                                            </Button>
                                          ) : (
                                            <Button
                                              className='btn btn-soft-info w-100 waves-effect waves-light'
                                              onClick={() => {
                                                setProductAliadoSelected({
                                                  id: node.id,
                                                  name: node.product,
                                                  value: node.productValue,
                                                  initial: node.initialFee
                                                    ? node.initialFee
                                                    : null,
                                                  description:
                                                    'Producto desde cotización'
                                                })
                                              }}
                                            >
                                              <i className='mdi mdi-check-circle-outline' />
                                              Seleccionar
                                            </Button>
                                          )}
                                        </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                      )}

                    {formik.values.creditType === CREDIT_TYPES.TRADICIONAL && (
                      <>
                        <Col
                          lg={6}
                          style={{
                            display: hiddenPrice ? 'none' : ''
                          }}
                        >
                          <div>
                            <Label
                              htmlFor='productValue-field'
                              className='form-label'
                            >
                              Valor del producto
                            </Label>
                            <InputCurrency
                              id='productValue-field'
                              name='productValue'
                              placeholder='Ingrese valord del producto'
                              value={formik.values.productValue}
                              className={`form-control ${
                                formik.touched.productValue &&
                                formik.errors.productValue
                                  ? 'is-invalid'
                                  : ''
                              } `}
                              onValueChange={(value, name) =>
                                formik.setFieldValue(name, value)
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.productValue &&
                              formik.errors.productValue && (
                                <div
                                  style={{
                                    width: '100%',
                                    marginTop: '0.25rem',
                                    fontSize: '0.875em',
                                    color: '#f06548'
                                  }}
                                >
                                  {formik.errors.productValue}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <Label
                              htmlFor='initialFee-field'
                              className='form-label'
                            >
                              Cuota inicial
                            </Label>
                            <InputCurrency
                              id='initialFee-field'
                              name='initialFee'
                              placeholder='Ingrese cuota incial'
                              className={`form-control ${
                                formik.touched.initialFee &&
                                formik.errors.initialFee
                                  ? 'is-invalid'
                                  : ''
                              } `}
                              value={formik.values.initialFee}
                              onValueChange={(value, name) =>
                                formik.setFieldValue(name, value)
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.initialFee &&
                              formik.errors.initialFee && (
                                <div
                                  style={{
                                    width: '100%',
                                    marginTop: '0.25rem',
                                    fontSize: '0.875em',
                                    color: '#f06548'
                                  }}
                                >
                                  {formik.errors.initialFee}
                                </div>
                              )}
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div>
                            <Label htmlFor='term-field' className='form-label'>
                              Plazo
                            </Label>
                            <Input
                              id='term-field'
                              name='term'
                              type='number'
                              placeholder='Ingrese el plazo'
                              value={formik.values.term}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={`form-control ${
                                formik.touched.term && formik.errors.term
                                  ? 'is-invalid'
                                  : ''
                              } `}
                            />
                            {formik.touched.term && formik.errors.term && (
                              <div
                                style={{
                                  width: '100%',
                                  marginTop: '0.25rem',
                                  fontSize: '0.875em',
                                  color: '#f06548'
                                }}
                              >
                                {formik.errors.term}
                              </div>
                            )}
                          </div>
                        </Col>
                      </>
                    )}

                    {idQuotation && Number(idQuotation) && dataQuotation && (
                      <Col lg={6}>
                        <div>
                          <Label />
                          <Alert
                            color={
                              productsSelected.length > 0
                                ? 'success'
                                : 'warning'
                            }
                            className='d-flex align-items-center gap-1'
                          >
                            <i
                              className={`mdi fs-3 ${
                                productsSelected.length > 0
                                  ? 'mdi-check-circle-outline'
                                  : 'mdi-alert-circle-outline'
                              }`}
                            />
                            Tienes {productsSelected.length} producto(s)
                            seleccionado(s)
                          </Alert>
                        </div>
                      </Col>
                    )}
                    {idQuotationAliado &&
                      Number(idQuotationAliado) &&
                      dataQuotationAliado && (
                        <Col lg={6}>
                          <div>
                            <Label />
                            <Alert
                              color={
                                productAliadoSelected ? 'success' : 'warning'
                              }
                              className='d-flex align-items-center gap-1'
                            >
                              <i
                                className={`mdi fs-3 ${
                                  productsSelected.length > 0
                                    ? 'mdi-check-circle-outline'
                                    : 'mdi-alert-circle-outline'
                                }`}
                              />
                              Has seleccionado un producto
                            </Alert>
                          </div>
                        </Col>
                      )}
                  </Row>
                </CardBody>
                <CardFooter>
                  <div className='hstack gap-2 justify-content-end'>
                    {/* <Button
                      color='light'
                      onClick={() => {
                        setOpen(false)
                      }}
                    >
                      Cancelar{' '}
                    </Button> */}
                    <Button
                      type='submit'
                      color='success'
                      id='add-btn'
                      disabled={loadingCreate}
                    >
                      {loadingCreate && <Spinner size='sm' />}
                      Crear crédito
                    </Button>
                  </div>
                </CardFooter>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>

      {isOpenPreCredit &&
        dataPreCredit &&
        dataPreCredit.oldCreditByCustomer?.length > 0 && (
          <ModalInfoPreCredit
            isOpen={isOpenPreCredit}
            info={dataPreCredit.oldCreditByCustomer}
            onClose={onClosePreCredit}
          />
        )}
    </div>
  )
}
