import {
  COLORS_CREDIT_LINE,
  STATUS_FORBBIDEN_CHANGE_ENITIES,
  commaDecimalPointThousands,
  pointDecimalCommaThousands
} from './consts'

export const VERSION = 'v1.1.13'

/** @returns {number} */
/** @param {string} str */

export const parseId = (str) => {
  if (!str) return 0
  const decode = window.atob(str)

  const id = decode.split(':')[1]

  return Number(id) || 0
}
/**
 * @returns {string}
 * @param {string} numStr
 * */
export const parsePrice = (numStr) => {
  let num = Number(numStr)
  let objMoney = {
    locale: 'es-CO',
    currency: 'COP',
    minimumfractiondigits: '0'
  }

  const objStr = window.localStorage.getItem('money')
  if (objStr) {
    try {
      objMoney = JSON.parse(objStr)
    } catch (error) {
      console.log('error', error)
    }
  }

  if (isNaN(num)) {
    num = 0
  }
  // if (num === 0) return '$0.00'

  const { currency, locale, minimumfractiondigits } = objMoney

  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: Number(minimumfractiondigits)
  }).format(num)
}

/**
 * @param {[{node: {value:string, name: string}}]} arr
 * @param {string | null} exclude
 * @returns { string }
 */
export const getTotal = (arr, exclude = '') => {
  let tot = 0
  arr.forEach(({ node }) => {
    const price = node.name === exclude ? 0 : Number(node.value)
    tot += price
  })
  return parsePrice(tot)
}
/**
 * @param {[{node: {value:string, name: string}}]} arr
 * @param {string | null} exclude
 * @returns { number }
 */
export const getTotalWithOutParse = (arr, exclude = '') => {
  let tot = 0
  arr.forEach(({ node }) => {
    const price = node.name === exclude ? 0 : Number(node.value)
    tot += price
  })
  return tot
}

/**
 *
 * @param {string} basePrice
 * @param {[{node: {value:string}}]} discounts
 * @param {[{node: {value:string}}]} prices
 * @param {string | null | undefined} deleteField
 * @returns {string}
 */
export const getFullTotal = (
  basePrice,
  discounts,
  prices,
  deleteField = ''
) => {
  const base = Number(basePrice)
  const totDis = Number(parsePriceToNum(getTotal(discounts)))
  const totPrices = Number(parsePriceToNum(getTotal(prices, deleteField)))

  const totFull = base + totPrices - totDis

  return parsePrice(totFull)
}
/**
 *
 * @param {string} basePrice
 * @param {[{node: {value:string}}]} discounts
 * @param {[{node: {value:string}}]} prices
 * @param {string | null | undefined} deleteField
 * @returns {string}
 */
export const getFullTotalNoParse = (
  basePrice,
  discounts,
  prices,
  deleteField = ''
) => {
  const base = Number(basePrice)
  const totDis = getTotalWithOutParse(discounts)
  const totPrices = getTotalWithOutParse(prices, deleteField)

  const totFull = base + totPrices - totDis

  return totFull
}

export const getColorByStatusPrint = (status) => {
  const OPTIONS = {
    SIN_ESTADO: '#2125291a',
    SIN_INTERÉS: '#2125291a',
    CONTINUA_INTERESADO: '#dff0fa',
    EN_FACTURACIÓN: '#dff0fa',
    FACTURADO: 'green',
    EN_SOLICITUD_DE_CRÉDITO: '#dff0fa',
    ANULADO: '#f06548',
    VENCIDO: 'yellow'
  }
  return OPTIONS[status] || '#9e9696'
}
/**
 * @param {string} permissionName
 * @param {string[]} permissions
 */
export const hasPermission = (permissions, permissionName) => {
  if (!permissions) return false
  return permissions.some((permission) => permission === permissionName)
}
/**
 *  @param {string} uuid
 *  @returns {boolean}
 */
export const isValidUUID = (uuid) => {
  const uuidPattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
  return uuidPattern.test(uuid)
}

/** @param {string} numStr */
export const parsePriceToNum = (numStr) => {
  const str = window.localStorage.getItem('money')
  const locale = JSON.parse(str)?.locale || 'es-CO'
  // const firstChar = numStr[0]
  if (commaDecimalPointThousands[locale]) {
    const res = numStr.replace(/\./g, '').replace(/,/, '.')
    // return Number(firstChar) ? res : Number(res.slice(1)).toString()
    return Number(res.replace(/[^\d.]/g, '')).toString()
  }
  if (pointDecimalCommaThousands[locale]) {
    const res = numStr.replace(/,/g, '')
    return Number(res.replace(/[^\d.]/g, '')).toString()
  }
  return numStr
}

/**
 * @param {string} priceStr
 * @returns {number}
 */
export const inversePrice = (priceStr) => Number(parsePriceToNum(priceStr))

/**
 * @param {{[key: string]: string}} obj
 * @returns {[{formId: number, sections: {sectionId: number, response: {name: string, value: string}[]}[]}]}
 */
export const getFormParse = (obj) => {
  const REGEX = /^(?=.*[.,€])(?:[.,\d$€ ]+)$/
  const arrayFinal = []
  Object.keys(obj).forEach((clave) => {
    const [formId, sectionId, name] = clave.split('-')
    const value = obj[clave]
    if (sectionId == null || name == null) return
    // Buscamos si ya existe un elemento con el mismo formId en el arrayFinal
    const formIndex = arrayFinal.findIndex(
      (item) => item.formId === Number(formId)
    )

    if (formIndex !== -1) {
      // Si existe, buscamos si ya existe una sección con el mismo sectionId
      const sectionIndex = arrayFinal[formIndex].sections.findIndex(
        (section) => section.sectionId === Number(sectionId)
      )

      if (sectionIndex !== -1) {
        // Si existe, agregamos la respuesta a esa sección
        arrayFinal[formIndex].sections[sectionIndex].response.push({
          name,
          value: REGEX.test(value)
            ? parsePriceToNum(value.replace(/[^,\d.]/g, ''))
            : value
        })
      } else {
        // Si no existe, creamos la sección con la respuesta
        arrayFinal[formIndex].sections.push({
          sectionId: Number(sectionId),
          response: [
            {
              name,
              value: REGEX.test(value)
                ? parsePriceToNum(value.replace(/[^,\d.]/g, ''))
                : value
            }
          ]
        })
      }
    } else {
      // Si no existe, creamos el elemento con el formId y la sección con la respuesta
      arrayFinal.push({
        formId: Number(formId),
        sections: [
          {
            sectionId: Number(sectionId),
            response: [
              {
                name,
                value: REGEX.test(value)
                  ? parsePriceToNum(value.replace(/[^,\d.]/g, ''))
                  : value
              }
            ]
          }
        ]
      })
    }
  })
  return arrayFinal
}

/**
 * @typedef {Object} props
 * @property {boolean | null} isAdd
 * @property {string} permissionAdmin
 * @property {string[]} permissions
 * @property {'APROBADO' | 'RECHAZADO' | 'PREAPROBADO'} statusCredit
 * @property {string | undefined} permissionToEdit
 * @property {string | undefined} permissionToAdd
 * @param {props}
 * @returns {boolean}
 */
export const canEditOrAdd = ({
  isAdd,
  permissionAdmin = '',
  permissions,
  statusCredit,
  permissionToEdit,
  permissionToAdd
}) => {
  const canEditAllCredit = hasPermission(permissions, permissionAdmin)

  if (canEditAllCredit) return true

  const canEditEntity = hasPermission(permissions, permissionToEdit)
  const isStatusForbidden =
    STATUS_FORBBIDEN_CHANGE_ENITIES.includes(statusCredit)

  const isStatusEmpty = !statusCredit

  if (isAdd) {
    const canAdd = hasPermission(permissions, permissionToAdd)
    return isStatusForbidden || isStatusEmpty ? false : canAdd
  }
  if (canEditEntity) return !(isStatusForbidden || isStatusEmpty)

  return false
}

/** @param {string} url */
export const getIcon = (url) => {
  if (!url) return 'mdi-file-excel-outline'
  const imgs = [
    'bmp',
    'gif',
    'jpg',
    'jpge',
    'tif',
    'png',
    'svg',
    'jpeg',
    'tiff'
  ]
  const excel = ['xlsx', 'xlsm', 'xlsb', 'xltx']
  const { pathname: pathFull } = new URL(url)
  if (!pathFull) return 'mdi-file-outline'
  const pathname = pathFull.split('.')?.at(-1)
  if (!pathname) return 'mdi-file-outline'
  // console.log('pathname', pathname)
  if (imgs.includes(pathname.toLowerCase())) return 'mdi-image-outline'
  if (pathname.toLowerCase().includes('pdf')) {
    return 'mdi-file-document-outline'
  }
  if (excel.includes(pathname.toLowerCase())) return 'mdi-microsoft-excel'
  if (pathname.toLowerCase().includes('docx')) return 'mdi-microsoft-word'
  return 'mdi-file-outline'
}

export const getColorBg = (url) => {
  if (!url) return 'muted'
  const imgs = [
    'bmp',
    'gif',
    'jpg',
    'jpge',
    'tif',
    'png',
    'svg',
    'jpeg',
    'tiff'
  ]
  const excel = ['xlsx', 'xlsm', 'xlsb', 'xltx']
  const { pathname: pathFull } = new URL(url)
  if (!pathFull) return 'info'
  const pathname = pathFull.split('.')?.at(-1)
  if (!pathname) return 'info'
  if (imgs.includes(pathname.toLowerCase())) return 'dark'
  if (pathname.toLowerCase().includes('pdf')) {
    return 'danger'
  }
  if (excel.includes(pathname.toLowerCase())) return 'success'
  if (pathname.toLowerCase().includes('docx')) return 'info'
  return 'info'
}

export const getColorLineCredit = (name) => {
  return COLORS_CREDIT_LINE[name?.toUpperCase() || 'n/a'] || 'dark'
}

export const trasnformToArr = (objOrArr) => {
  const arr =
    objOrArr != null ? (Array.isArray(objOrArr) ? objOrArr : [objOrArr]) : []

  return arr
}
