import { ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import createUploadLink from 'apollo-upload-client/public/createUploadLink'

export const API =
  process.env.NODE_ENV === 'production'
    ? `https://${
        window.location.host.split('.')[0]
      }.backend.${window.location.host.split('.').slice(1).join('.')}`
    : 'https://totalsales.backend.namtrik.dev'
// export const API = 'https://totalsales.backend.crediscore.co'
// export const API = 'https://totalsales.backend.totalsales.co'

const httpLink = createUploadLink({
  uri: API + '/api'
  /* formDataAppendFile: (formData, fieldName, file) => {
    let count = 0

    formData.forEach((_value) => {
      count++
    })

    formData.set(`${count}`, file)
  } */

  // uri: 'https://7a87-8-242-210-20.ngrok-free.app/api',
  /*   headers: {
    authorization: window.localStorage.getItem('token')
      ? `Bearer ${JSON.parse(window.localStorage.getItem('token'))}`
      : ''
  },
  formDataAppendFile: (formData, name, file) => {
    // const formData = new FormData()
    console.log('name, file', name, file)
    // formData.append(fileFieldName, file)
    formData.delete('map')
    formData.delete('1')
    formData.append('map', '{"0": ["variables.input.image"]}')
    formData.append('0', file, file.name)

    console.log('formData.get("operations")', formData.get('operations'))
  } */
  /* isExtractableFile: (vale) => {
    console.log('vale', vale)
    return vale
  } */
  // uri: 'https://totalsales.backend.crediscore.co/api'
  // uri: `https://${window.location.host.split(".")[0]}.backend.clapi.co/api`,
})

const authLink = setContext((param, param2) => {
  // get the authentication token from local storage if it exists
  const tokenL = window.localStorage.getItem('token')
  const token = JSON.parse(tokenL)
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      // ...headers,
      Authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false
  }),
  connectToDevTools: true
})

/** @return {string} */
/** @param {string} token */
export const getUrlWs = (token) => {
  const URL_SOCKET =
    process.env.NODE_ENV === 'production'
      ? `${API.replace('https', 'wss')}/ws/base?authorization=Bearer%20${token}`
      : 'wss://socketsbay.com/wss/v2/1/demo/'
  return URL_SOCKET
}
